import jsCookie from 'js-cookie';
const cookieName = process.env.VUE_APP_COOKIE_NAME;
const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;

const storage = {
  cookies: {
    get: () => {
      return jsCookie.get(cookieName);
    },

    set: (value) => {
      let settings = { expires: 365, path: '', domain: cookieDomain, samesite: "strict"};
      if(cookieDomain !== "localhost"){
        settings.secure = true;
      }
      return jsCookie.set(cookieName, value, settings);
    },

    remove: () => {
      return jsCookie.remove(cookieName,{path: '', domain: cookieDomain});
    },
  },
  localStorage: {
    get: () => {
      return localStorage.getItem(cookieName)
    },
    set: (value) => {
      return localStorage.setItem(cookieName, value);
    },
    remove: () => {
      return localStorage.removeItem(cookieName);
    }
  }
};
export default function(name){
  return storage[name];
}
