import checkPermission from '@/modules/users/router/middleware/permission';

export default [
  /*
    {
        path: '/my-activity',
        name: 'callcenter.activity',
        component: () => import('../views/Activity'),
    },
    */
    {
        path: '/my-calls',
        name: 'callcenter.myCalls',
        component: () => import('../views/MyCalls'),
    },
    {
        path: '/calls',
        name: 'callcenter.calls',
        component: () => import('../views/AllCalls'),
    },
    {
        path: '/calls/:callId',
        name: 'callcenter.calls.call',
        component: () => import('../views/Calls/Call'),
    },
    {
        path: '/user/:userId',
        name: 'callcenter.team.member',
        component: () => import('../views/TeamMember'),
        meta: {
          middleware: checkPermission('users:user.search')
        },
    },
    /*
    {
        path: '/todo',
        name: 'calendar.todo',
        component: () => import('../views/Calendar/Todo'),
        meta: {
          middleware: checkPermission('appointments.mine.view')
        },
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('../views/Calendar/Calendar'),
    },
    */
    {
        path: '/channels',
        name: 'callcenter.channels',
        component: () => import('../views/Channels/Channels'),
        meta: {
          middleware: checkPermission('rtc:channels.search')
        },
    },
    {
        path: '/channels/:channelId',
        component: () => import('../views/Channels/Channel/Channel'),
        children: [
          {
            path: '',
            name: 'callcenter.channel',
            component: () => import('../views/Channels/Channel/Channel.Settings'),
            meta: {
              middleware: checkPermission('rtc:channel.findBy.id')
            },
          },
          {
            path: 'delete',
            name: 'callcenter.channel.delete',
            component: () => import('../views/Channels/Channel/Channel.Delete'),
            meta: {
              middleware: checkPermission('rtc:channel.delete')
            },
          },
          {
            path: 'origins',
            name: 'callcenter.channel.origins',
            component: () => import('../views/Channels/Channel/Channel.Origins'),
            meta: {
              middleware: checkPermission('rtc:channel.update')
            },
          },
          {
            path: 'edit',
            name: 'callcenter.channel.edit',
            component: () => import('../views/Channels/Channel/Channel.Edit'),
            meta: {
              middleware: checkPermission('rtc:channel.update')
            },
          },
          {
            path: 'users',
            name: 'callcenter.channel.users',
            component: () => import('../views/Channels/Channel/Channel.ManageUsers'),
            meta: {
              middleware: checkPermission(['rtc:channel.user.assign','rtc:channel.user.unassign'])
            },
          }
        ]
    },
    {
        path: '/channel/:channelId',
        name: 'channel.board',
        component: () => import('../views/Channel/Channel'),
        meta: {
          middleware: checkPermission('rtc:channel.findBy.id')
        },
    },
    {
        path: '/widgets',
        name: 'widgets',
        component: () => import('../views/Widgets/Widgets'),
        meta: {
          middleware: checkPermission('rtc:widgets.search')
        },
    },
    {
        path: '/widgets/create',
        name: 'widgets.widget.create',
        component: () => import('../views/Widgets/Widget.Create'),
        meta: {
          middleware: checkPermission('rtc:widget.create')
        },
    },
    {
        path: '/widgets/:widgetId',
        name: 'widgets.widget.edit',
        component: () => import('../views/Widgets/Widget.Edit'),
        meta: {
          middleware: checkPermission('rtc:widget.update')
        },
    },
];
