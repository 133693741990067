import checkPermission from '@/modules/users/router/middleware/permission';

export default [
  {
      path: '/crm/followups',
      name: 'crm.followups',
      component: () => import('../views/Followups'),
      meta: {
        middleware: checkPermission('crm:contacts.search')
      },
  },
  {
      path: '/crm/contact/:contactId',
      name: 'crm.contact',
      component: () => import('../views/Contact/Contact.Overview'),
      meta: {
        middleware: checkPermission('crm:contact.findBy.id')
      },
  },
  {
      path: '/crm/calendar',
      name: 'crm.calendar',
      component: () => import('../views/CalendarSlim/Calendar'),
      meta: {
        middleware: checkPermission('crm:calendar.event.search')
      },
  },
  {
      path: '/crm/calendar/settings',
      name: 'crm.calendar.settings',
      component: () => import('../views/CalendarSlim/Settings'),
      meta: {
        middleware: checkPermission('crm:calendar.user.days.settings.create')
      },
  },
]
