export default {
    namespaced: true,
    state: {
      channels: [],
      calling: [],
      inCall: [],
      users: [],
      activeCall: null,
      initialized: false,
      iceServers: [],
      timeElapsedIntervals: [],
      waitingRoom: [],
      usersOnlineInPlatform: [],
      userCallAvailability: true
    },
    mutations: {
      setChannels(state, channels){
        state.channels = Object.assign([],channels);
      },

      setCalling(state, calling){
        state.calling = Object.assign([],calling);
      },

      setInCall(state, inCall){
        inCall = inCall.map(item => {
          item.isInCall.timeElapsed = item.isInCall.since;
          return item;
        });
        state.inCall = Object.assign([],inCall);
      },

      updateInCallItem(state,{index,timeElapsed}){
        state.inCall[index].isInCall.timeElapsed = timeElapsed;
      },

      setActiveCall(state, data){
        state.activeCall = data;
      },

      setUsers(state, users){
        state.users = Object.assign([],users);
      },

      setUserStatus(state, {userId, newStatus}){
        state.users = Object.assign([],state.users.map(item => {
          if(item.userId === userId){
            item.activityStatus = newStatus;
          }
          return item;
        }));
      },

      setInitialization(state, value){
        state.initialized = value;
      },

      setIceServers(state, value){
        state.iceServers = value;
      },

      setTimeElapsedIntervals(state, data){
        state.timeElapsedIntervals = Object.assign([], data);
      },

      setWaitingRoom(state, data){
        state.waitingRoom = Object.assign([],data);
      },

      setUsersOnlineInPlatform(state, data){
        state.usersOnlineInPlatform = Object.assign([],data);
      },

      setUserCallAvailability(state, value){
        state.userCallAvailability = value;
      },
    },
    getters: {
      getChannels(state){
        return state.channels;
      },

      getCalling(state){
        return state.calling;
      },

      getInCall(state){
        return state.inCall;
      },

      getActiveCall(state){
        return state.activeCall;
      },

      getUsers(state){
        return state.users;
      },

      getMe(state){
        return function(userId){
          return state.users.find(o => o.userId === userId);
        }
      },

      getIceServers(state){
        return state.iceServers;
      },

      isInitialized(state){
        return state.initialized;
      },

      getTimeElapsedIntervals(state){
        return state.timeElapsedIntervals;
      },

      getWaitingRoom(state){
        return state.waitingRoom;
      },

      getUsersOnlineInPlatform(state){
        return state.usersOnlineInPlatform;
      },

      getUserCallAvailability(state){
        return state.userCallAvailability;
      }
    },
    actions: {
      addChannels({commit}, channels){
        return commit('setChannels', channels);
      },

      addCalling({commit}, calling){
        return commit('setCalling', calling);
      },

      async addInCall({commit,getters}, inCall){
        await commit('setInCall', inCall);

        getters['getTimeElapsedIntervals'].map(item => clearInterval(item));

        let getInCall = getters['getInCall'];
        let cacheIntervals = [];
        for(let index in getInCall){
          let item = getInCall[index];

          let interval = setInterval(function(){
            let value = (Date.now() - item.isInCall.since) / 1000;
            let timeElapsed = new Date(value * 1000).toISOString().substr(11, 8)
            commit('updateInCallItem',{index,timeElapsed});
          },1000);
          cacheIntervals.push(interval);
        }

        commit('setTimeElapsedIntervals', cacheIntervals);
      },

      addActiveCall({commit}, data){
        return commit('setActiveCall', data);
      },

      addUsers({commit}, users){
        return commit('setUsers', users);
      },

      addInitialization({commit},value){
        return commit('setInitialization', value);
      },

      updateUserStatus({commit}, data){
        return commit('setUserStatus', data);
      },

      addIceServers({commit}, data){
        return commit('setIceServers', data);
      },

      addWaitingRoom({commit}, data){
        return commit('setWaitingRoom', data);
      },

      addUsersOnlineInPlatform({commit}, data){
        return commit('setUsersOnlineInPlatform',data);
      },

      addUserCallAvailability({commit}, value){
        return commit('setUserCallAvailability',value);
      }

    }
};
