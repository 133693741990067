<template>
  <div class="sidebar-team">
    <b-link v-if="user && user.hasPermission('users:user.update.account')" :to="{name: 'users.manager.browse'}" class="sidebar-team-settings" v-b-tooltip.hover title="Manage your team">
      <BIconGearFill/>
    </b-link>
    <h6 class="sidebar-heading">
        <div class="small text-uppercase">
            My Team
        </div>
    </h6>

    <div class="sidebar-team-list-container">
      <div v-if="!isLoading">
          <ul class="sidebar-team-list" v-if="team.records.length > 0">
              <li v-for="item in team.records" v-bind:key="item.id">
                  <b-link
                    :to="{name: 'callcenter.team.member', params: {userId: item.id}}"
                    :active="sidebar && sidebar.active === 'member' && sidebar.member.get('id') === item.id"
                  >
                      <span class="team-member-noimg">
                        {{ item.nameInitials }}
                      </span>
                      {{ item.firstName }} {{ item.lastName }}
                      <BIconTelephoneXFill class="team-member-takeCalls-no" v-if="!isTakingCalls(item)" variant="danger" font-scale="1" title="Calls are disabled"/>
                      <span :class="setStatusClass(item)"></span>
                  </b-link>
              </li>
          </ul>
          <template v-else>
            <b-button v-if="user && user.hasPermission('users:user.create')" :to="{name: 'users.manager.user.create'}" variant="primary" size="sm" class="btn-block">Create the first member</b-button>
          </template>
      </div>
      <div v-else>
          <b-spinner small variant="light"></b-spinner>
      </div>
    </div>
  </div>
</template>
<script>
import {BIconGearFill,BIconTelephoneXFill} from 'bootstrap-vue';
export default {
    props: ['user','sidebar'],
    components: {
      BIconGearFill,BIconTelephoneXFill
    },
    data(){
      return {
        isLoading: false,
        team: {
          records: [],
          total: 0,
          pagination: {}
        }
      };
    },
    methods: {
      getMemberInitials(item){
        let name = [];
        if(item.firstName !== null && item.firstName.length > 0){
          name.push(item.firstName[0]);
        }
        if(item.lastName !== null && item.lastName.length > 0){
          name.push(item.lastName[0]);
        }
        return name.join('');
      },
      getMemberFullname(item){
        let name = [];
        if(item.firstName !== null && item.firstName.length > 0){
          name.push(item.firstName);
        }
        if(item.lastName !== null && item.lastName.length > 0){
          name.push(item.lastName);
        }
        return name.join(' ');
      },
      parseMember(item){
        item.nameInitials = this.getMemberInitials(item);
        item.fullName = this.getMemberFullname(item);
        return item;
      },
      parseTeam(records){
        return records.map(this.parseMember);
      },
      getTeam(){
        this.isLoading = true;
        return this.$api.get('users', {params: {perPage: 100}}).then(async res => {
            this.isLoading = false;
            this.team = res.data;
            let records = this.parseTeam(this.team.records);

            this.team.records = records.filter(o => o.id !== this.user.get('id'));

            return this.$store.dispatch('Callcenter/addUsers', records);
        });
      },
      setStatusClass(item){
        let userOnline = this.usersOnlineInPlatform.find(o => o.userId === item.id);
        return 'team-member-status '+(userOnline ? userOnline.platformStatus : 'offline');
      },

      isTakingCalls(item){
        let m = this.usersOnlineInPlatform.find(o => o.userId === item.id);
        return m ? m.takeCalls : true;
      }
    },

    mounted(){
      this.getTeam();
    },

    computed: {
      usersOnlineInPlatform(){
        return this.$store.getters['Callcenter/getUsersOnlineInPlatform'];
      }
    }

    /*
    computed: {
      onTeamUpdate(){
        return this.$store.getters['Notification/getRT'].find(o => o.event === 'onTeamUpdate');
      },

      onTeamStatusUpdate(){
        return this.$store.getters['Notification/getRT'].find(o => o.event === 'onTeamStatusUpdate');
      },
    },

    watch: {
      "onTeamUpdate": function(){
        this.$store.dispatch('Notification/removeEvent','onTeamUpdate');
        this.getTeam();
      },

      "onTeamStatusUpdate": function(data){
        if(!data){
          return;
        }

        let {newStatus, userId} = data.online;

        this.$store.dispatch('Notification/removeEvent','onTeamStatusUpdate');
        let index = this.team.records.findIndex(o => o.id === userId);
        if(index !== -1){
          this.team.records[index].activityStatus = newStatus;
        }
      },
    }
    */
}
</script>
