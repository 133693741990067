<template>
    <b-dropdown v-if="user" id="dropdown-user" size="sm" variant="link" right no-caret class="navbar-user">
        <template #button-content>
          <div :class="getUserStatusClass()">
            {{ user.getInitials() }}
          </div>
        </template>
        <!-- <b-dropdown-item :to="{name: 'app.user.account.camera'}">Camera Settings</b-dropdown-item>-->
        <b-dropdown-item v-if="user.hasPermission('rtc:channel.create')" :to="{name: 'callcenter.channels'}">My Channels</b-dropdown-item>
        <b-dropdown-item v-if="user.hasPermission('rtc:widgets.search')" :to="{name: 'widgets'}">My Widgets</b-dropdown-item>
        <b-dropdown-item v-if="user.hasPermission('users:user.create')" :to="{name: 'users.manager.browse'}">Manage Team</b-dropdown-item>
        <b-dropdown-divider v-if="user.hasPermission(['rtc:channel.create','rtc:channel.create'])"></b-dropdown-divider>
        <!-- <b-dropdown-item>Camera Settings</b-dropdown-item>-->
        <b-dropdown-item :to="{name: 'app.user.account'}">My Account</b-dropdown-item>
        <!--<b-dropdown-item :href="adminUrl" target="_blank">Administration</b-dropdown-item>-->
        <b-dropdown-item @click="logout()">Sign Out</b-dropdown-item>
    </b-dropdown>
</template>
<script>
export default {
    props: ['user'],
    components: {

    },
    data(){
      return {
        adminUrl: process.env.VUE_APP_ADMIN_URL
      };
    },
    methods: {
      getUserStatusClass(){
        return 'user-bubble-sm user-bubble-'+(this.myPlatformStatus ? this.myPlatformStatus.platformStatus : 'offline');
      },
      logout(){
        //this.$callstr.signal.emit('set-status', {userId: this.user.get('id'),status: "offline"});
        this.$store.dispatch('User/Auth/clearCookie').then(() => {
          window.location.href = this.$router.resolve({name: "app.user.login"}).href;
        });
      },
    },

    computed: {
      myPlatformStatus(){
        return this.$store.getters['Callcenter/getUsersOnlineInPlatform'].find(o => o.userId === this.user.get('id'));
      }
    }
}
</script>
