export default function(permissions){
  return function({next, store, router}) {
    let user = store.getters['User/Profile/getProfile'];
    if(!user.hasPermission(permissions)){
      router.push({name: "app.errors.403"});
      return next(false);
    }
    return next();
  }
}
