import API from 'nodesdk-api-wrapper';
import jsCookie from 'js-cookie';

export default {
  install(Vue){
    let api = API(process.env.VUE_APP_BASE_API_HOST);
    api.setDefaultHeaders({
      'X-Callstr-CSRF': jsCookie.get('_callstr_csrf')
    });
    Vue.prototype['$api'] = api;
  }
}
