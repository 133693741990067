import checkPermission from './../middleware/permission';
export default [
  {
      path: '/users',
      name: 'users.manager.browse',
      component: () => import('../../views/Manager/Users.vue'),
      meta: {
        middleware: checkPermission(['users:user.search','users:user.update.account'])
      }
  },
  {
      path: '/users/create',
      name: 'users.manager.user.create',
      component: () => import('../../views/Manager/User.Create.vue'),
      meta: {
        middleware: checkPermission('users:user.create')
      }
  },
  {
      path: '/users/:userId',
      component: () => import('../../views/Manager/User/User.vue'),
      meta: {
        middleware: checkPermission('users:user.update.account')
      },
      children: [
        {
          path: '',
          name: 'users.manager.user',
          component: () => import('../../views/Manager/User/User.Details.vue'),
          meta: {
            middleware: checkPermission('users:user.update.account')
          },
        },
        {
          path: 'change-password',
          name: 'users.manager.user.changePassword',
          component: () => import('../../views/Manager/User/User.ChangePassword.vue'),
          meta: {
            middleware: checkPermission('users:user.update.password')
          },
        },
        {
          path: 'delete',
          name: 'users.manager.user.delete',
          component: () => import('../../views/Manager/User/User.Delete.vue'),
          meta: {
            middleware: checkPermission('users:user.delete')
          },
        },
      ]
  },
];
