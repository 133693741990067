import Vue from 'vue';
import App from './App.vue';
//import './registerServiceWorker';
import router from './router';
import store from './store';
//import NodeSDKAPI from 'nodesdk-api-wrapper';
import API from '@/common/services/API';
//import US from '@/common/services/US';

Vue.config.productionTip = false;

import { BootstrapVue} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/common/assets/css/style.css';

Vue.use(BootstrapVue);

Vue.use(API);
//Vue.use(US);

import Callstr from '@/common/services/Callstr';
Vue.use(Callstr);

new Vue({
    router,
    store,
    render: h => h(App),
    created(){
      let self = this;
      window.addEventListener('beforeunload', function (e) {
        if(self.$store.getters['Callcenter/getActiveCall'] === null){
          //continue
        }
        else{
          // Cancel the event
          e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown

          let msg = "You have an ongoing call. Are you really sure you want to leave the page?";

          // Chrome requires returnValue to be set
          e.returnValue = msg;

          return msg;
        }
      });
    },
    mounted(){
      try{
        localStorage.setItem('callstr_version', process.env.VUE_APP_CALLSTR_VERSION);
      }catch(e){
        console.log("cannot write to localStorage",e);
      }
    }
}).$mount('#app');
