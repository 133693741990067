import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import middleware from '@/common/utils/middleware';
import routes from './routes';
import auth from '@/modules/users/router/middleware/auth';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => auth({store, router, to, from, next}));
router.beforeEach((to, from, next) => middleware({store, router, to, from, next}));

export default router;
