let audios = {
  incoming: new Audio(require('./../common/assets/sounds/sound_incoming.mp3')),
  waitingRoom: new Audio(require('./../common/assets/sounds/done.mp3')),
};

export default {
    namespaced: true,
    state: {
      canPlaySounds: false,
      incomingPlaying: false,
      waitingRoomPlaying: false
    },
    getters: {
      getIncomingPlaying(state){
        return state.incomingPlaying;
      },
      getWaitingRoomPlaying(state){
        return state.incomingPlaying;
      },

      getCanPlaySounds(state){
        return state.canPlaySounds;
      },

      getAudioEntity(){
        return function(name){
          return audios[name];
        }
      }
    },
    mutations: {
      setPlayIncomingSound(state){
        state.incomingPlaying = true;
      },

      setStopIncomingSound(state){
        state.incomingPlaying = false;
      },

      setPlayWaitingRoomSound(state){
        state.waitingRoomPlaying = true;
      },

      setStopWaitingRoomSound(state){
        state.waitingRoomPlaying = false;
      },

      setCanPlaySounds(state, value){
        state.canPlaySounds = value;
      }
    },
    actions: {
      playIncomingSound({commit, getters}){
        if(getters.getIncomingPlaying){
          //return false;
        }

        audios.incoming.muted = false;
        audios.incoming.currentTime = 0;
        audios.incoming.loop = true;
        audios.incoming.play();

        return commit('setPlayIncomingSound');
      },

      stopIncomingSound({commit}){
        audios.incoming.currentTime = 0;
        audios.incoming.pause();
        return commit('setStopIncomingSound');
      },

      playWaitingRoomSound({commit, getters}){
        if(getters.waitingRoomPlaying){
          return false;
        }

        audios.waitingRoom.muted = false;
        audios.waitingRoom.currentTime = 0;
        audios.waitingRoom.loop = false;
        audios.waitingRoom.play();

        return commit('setPlayWaitingRoomSound');
      },

      stopWaitingRoomSound({commit}){
        audios.waitingRoom.currentTime = 0;
        audios.waitingRoom.pause();
        return commit('setStopWaitingRoomSound');
      },

      tryPlaySound(){
        audios.incoming.currentTime = 0;
        audios.incoming.muted = true;
        return audios.incoming.play()
        .then(() => {
          audios.incoming.muted = false;
          audios.incoming.pause();
          audios.incoming.currentTime = 0;
          return true;
        })
        .catch(e => {
          audios.incoming.muted = false;
          console.log('play error',e);
          return false;
        });
      },

      enableSounds({commit}){
        return commit('setCanPlaySounds',true);
      }
    }
};
