import config from './../config';
import Vue from 'vue';
import Vuex from 'vuex';
import Notification from './Notification';
import Sound from './Sound';

Vue.use(Vuex);

var storeModules = {
  Notification: Notification,
  Sound: Sound
};
for(let module in config.modules){
    storeModules = Object.assign(storeModules,config.modules[module].store);
}

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: storeModules
});
