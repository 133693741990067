import authstorage from '@/common/services/authstorage';
const storage = authstorage('cookies');

export default {
    namespaced: true,
    state: {
        token: null
    },
    mutations: {
        setToken(state, value){
            state.token = value;
        }
    },
    getters: {
        getToken(state){
            return state.token;
        }
    },
    actions: {
        check(context){
            //let token = localStorage.getItem(process.env.VUE_APP_COOKIE_NAME);
            let token = storage.get();

            if(token === null){
                context.dispatch('User/Profile/setProfile',null,{root: true});
                return context.commit('setToken', null);
            }
            else{
                let api = this._vm.$api;
                api.setDefaultHeaders({'Authorization': "Bearer "+token});
                
                return api.get('me').then(res => {
                    //context.commit('setToken', token);
                    return context.dispatch('User/Profile/setProfile',res.data,{root: true});
                })
                .catch(() => {
                  return context.commit('setToken', null);
                });
            }
        },

        setCookie({commit},accessToken){
          //localStorage.setItem(process.env.VUE_APP_COOKIE_NAME, accessToken);
          storage.set(accessToken);
          return commit('setToken', accessToken);
        },

        clearCookie(context){
            //localStorage.removeItem(process.env.VUE_APP_COOKIE_NAME);
            storage.remove();
            context.dispatch('User/Profile/setProfile',null,{root: true});
            return context.commit('setToken', null);
        },

        isLoggedIn(){
          let token = storage.get();
          if(!token){
            return false;
          }

          let api = this._vm.$api;
          return api.get('me',{
            headers: {'Authorization': "Bearer "+token}
          }).then(() => {
              return true;
          })
          .catch(() => {
            return false
          });
        }
    }
};
