<template>
    <div id="agentWindow">
      <AgentWindowDesktop v-if="isPlatform(['desktop'])" :activeCall="activeCall" :platform="platform" />
      <AgentWindowMobile v-if="isPlatform(['mobile','tablet'])" :activeCall="activeCall" :platform="platform" />
    </div>
</template>

<script>
import Bowser from "bowser";

export default {
  props: ['activeCall'],
  components: {
    AgentWindowDesktop: () => import('./Platforms/Desktop'),
    AgentWindowMobile: () => import('./Platforms/Mobile'),
  },

  data(){
    return {
      browser: null,
      platform: null,
      defaultPlatform: "desktop"
    };
  },

  methods: {
    isPlatform(platforms){
      return platforms.indexOf(this.platform) !== -1 ? true : false;
    }
  },

  mounted(){
    this.platform = this.defaultPlatform;
    this.browser = Bowser.parse(window.navigator.userAgent);
    if(this.browser.platform && this.browser.platform.type){
      this.platform = this.browser.platform.type;
    }
  }
};
</script>
