<template>
  <b-form-checkbox @change="setCallAvailability()" v-model="takeCalls" name="check-button" switch>
    <span style="position: relative; top: 2px;">{{takeCalls ? 'Available for calls' : 'Calls disabled'}}</span>
  </b-form-checkbox>
</template>
<script>
export default {
    props: ['user'],
    components: {},
    data(){
      return {
        takeCalls: true
      };
    },
    methods: {
      setCallAvailability(){
        this.$store.dispatch('Callcenter/addUserCallAvailability', this.takeCalls).then(() => {
          this.$callstr.signal.emit('set-call-availability', {takeCalls: this.takeCalls});
        })
      }
      /*
        setStatus(id){
            this.updateStatus(id);
            this.$callstr.signal.emit('set-status', {userId: this.user.get('id'),status: this.status.id});
        },

        updateStatus(id){
            this.status = this.statuses.find(o => o.id === id);
        }
        */
    },

    mounted(){

    },

    computed: {
      /*
      onMyStatusUpdate(){
        return this.$store.getters['Notification/getRT'].find(o => o.event === 'onMyStatusUpdate');
      },
      */
    },

    /*
    watch: {
      "onMyStatusUpdate": function(data){
        if(!data){
          return;
        }

        let {newStatus} = data.online;

        this.$store.dispatch('Notification/removeEvent','onMyStatusUpdate');
        this.updateStatus(newStatus);
      },
    }
    */
}
</script>
