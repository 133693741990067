export default async function auth({to, router, next, store}) {
    if(to.name === "app.user.login"){
        return next();
    }

    if(store.getters['User/Profile/getProfile']){
        next();
    }
    else{
        store.dispatch('User/Auth/check').then(() => {
            if(store.getters['User/Profile/getProfile']){
                next();
            }
            else{
                router.push({name: "app.user.login"});
                return next(false);
            }
        }).catch(() => {
          router.push({name: "app.user.login"});
          return next(false);
        });
    }
}
