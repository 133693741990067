//import Callstr from '@/temp/callstr-sdk';
import Callstr from 'callstr-sdk';
import authstorage from '@/common/services/authstorage';
import cookies from '@/common/services/cookies';
const authStorage = authstorage('cookies');
const cookieStorage = cookies('cookies');

export default function install(Vue){
  let accessToken = authStorage.get();
  if(!accessToken){
    return;
  }

  Vue.prototype['$callstr'] = new Callstr({
    auth: {
        accessToken: accessToken,
        csrfToken: cookieStorage.get('_callstr_csrf')
    },
    hosts: {
        signalling: process.env.VUE_APP_LIVE_SERVER_HOST
    }
  });
}
