<template>
    <div v-if="user">
        <Main :user="user"/>
    </div>
    <div id="app" v-else>
        <router-view />
    </div>
</template>

<script>
import Main from './views/Main';
export default {
    components: {
      Main
    },
    computed: {
        user(){
            return this.$store.getters['User/Profile/getProfile'];
        }
    }
}
</script>
