import User from './../../models/User';

export default {
    namespaced: true,
    state: {
        data: null
    },
    getters: {
        getProfile(state){
            return state.data;
        }
    },
    mutations: {
        setData(state, value){
            state.data = value;
        }
    },
    actions: {
        setProfile(context, data){
            context.commit('setData',(data ? new User(data) : null));
        }
    },
    modules: {
        
    }
}
