<template>
    <div class="page-nav">
        <div class="page-nav-left">
            <ul class="page-nav-list">
              <li>
                <UserStatus :user="user"/>
              </li>
              <li v-if="activeCall">
                <AgentWindow :activeCall="activeCall"/>
              </li>
              <li v-if="incomingCalls > 0" class="page-nav-incoming-notif">
                {{incomingCalls}} incoming calls
              </li>
              <li v-if="user.hasPermission('rtc:widgets.search') && channels && channels.length === 0">
                <QuickSetup :user="user"/>
              </li>
              <li v-if="hasNewVersion">
                <b-button variant="link" size="sm" @click="reloadApp()" class="page-nav-incoming-notif">A new version available! Click here to refresh!</b-button>
              </li>
            </ul>
        </div>
        <div class="page-nav-user">
          <NavbarUser v-if="user" :user="user"/>
        </div>
    </div>
</template>
<script>
import NavbarUser from '@/modules/users/components/Navbar.User';
import UserStatus from '@/modules/callcenter/components/User.Status';
import AgentWindow from '@/modules/callcenter/components/AgentWindow';
export default {
    props: ['user','incomingCallsNotification'],
    components: {
      NavbarUser,
      UserStatus,
      QuickSetup: () => import('./QuickSetup'),
      AgentWindow,
    },
    data(){
      return {
        hasNewVersion: false
      }
    },
    methods: {
      reloadApp(){
        window.location.reload(true);
      }
    },
    computed: {
        activeCall(){
            return this.$store.getters['Callcenter/getActiveCall'];
        },
        incomingCalls(){
          return this.$store.getters['Callcenter/getCalling'].length;
        },
        channels(){
          return this.$store.getters['Callcenter/getChannels'];
        }
    },

    mounted(){
      this.$root.$on('onAgentEvent',({event}) => {
        let callId = this.activeCall.client.callId;

        switch(event){
          case "hangup-received":
            this.$store.dispatch('Callcenter/addActiveCall', null).then(() => {
              this.$router.push({name: "callcenter.calls.call", params: {callId: callId}});
            });
          break;

          case "hangup-made":
            this.$store.dispatch('Callcenter/addActiveCall', null).then(() => {
              this.$router.push({name: "callcenter.calls.call", params: {callId: callId}});
            });
            break;

          case "answer-made":
            this.$store.dispatch('Sound/stopIncomingSound');
            break;
        }
      })

      this.$callstr.signal.on('new-version',() => {
        this.hasNewVersion = true;
      })
    }
}
</script>
