export default [
    {
        path: '/account',
        name: 'app.user.account',
        component: () => import('../../views/MyAccount/Account.vue')
    },
    {
        path: '/account/change-password',
        name: 'app.user.account.changePassword',
        component: () => import('../../views/MyAccount/ChangePassword.vue')
    },
    {
        path: '/account/change-email',
        name: 'app.user.account.changeEmail',
        component: () => import('../../views/MyAccount/ChangeEmail.vue')
    },
    /*
    {
        path: '/account/camera-settings',
        name: 'app.user.account.camera',
        component: () => import('../../views/MyAccount/Settings.Camera.vue')
    }
    */
];
