import Auth from './Auth';
import Profile from './Profile';

export default {
    namespaced: true,
    state: {
        
    },
    mutations: {
        
    },
    actions: {
        
    },
    modules: {
        Auth: Auth,
        Profile: Profile
    }
};
