import Users from '@/modules/users';
import Callcenter from '@/modules/callcenter';
import CRM from '@/modules/crm';

export default{
    modules: {
        Users: Users,
        Callcenter: Callcenter,
        CRM: CRM
    }
}
