<template>
    <div class="sidebar">
      <div class="sidebar-logo">
          <b-link class="sidebar-brand" :to='{name: "app.dashboard"}'>
              <img src="@/common/assets/img/logo.png" class="sidebar-logo-img">
              <span class="sidebar-logo-text">
                  <span class="sidebar-logo-text-small">the power of</span><br>
                  <span class="sidebar-logo-text-big">Social Selling</span>
              </span>
          </b-link>
      </div>
      <div class="sidebar-nav">
          <ul>
              <li>
                  <b-link v-bind:class="setActiveSidebar('dashboard')" :to='{name: "app.dashboard"}'>
                    <b-badge v-if="incomingCalls > 0" class="float-right ml-3 mt-1" variant="success">{{incomingCalls}}</b-badge>
                    <BIconBroadcast v-else class="text-muted sidebar-item-icon"/>
                    <span class="sidebar-item-title">Live Board</span>
                  </b-link>
              </li>
              <li v-if="user && user.hasPermission('rtc:calls.search')">
                  <b-link v-bind:class="setActiveSidebar('myCalls')" :to='{name: "callcenter.myCalls"}'>
                      <BIconTelephone class="text-muted sidebar-item-icon"/>
                      <span class="sidebar-item-title">Call History</span>
                  </b-link>
              </li>
              <li v-if="user && user.hasPermission('crm:contacts.search')">
                  <b-link :to="{name: 'crm.followups'}" v-bind:class="setActiveSidebar('crm.followups')">
                    <BIconPersonLinesFill class="text-muted sidebar-item-icon"/>
                    <span class="sidebar-item-title">Contacts</span>
                  </b-link>
              </li>
              <li v-if="user && user.hasPermission('crm:calendar.event.search')">
                  <b-link :to="{name: 'crm.calendar'}" v-bind:class="setActiveSidebar('crm.calendar')">
                    <BIconCalendarWeek class="text-muted sidebar-item-icon"/>
                    <span class="sidebar-item-title">Calendar</span>
                  </b-link>
              </li>
          </ul>
          <Team v-if="user && user.hasPermission('users:user.search')" :sidebar="sidebar" :user="user"/>
      </div>
    </div>
</template>
<script>
import Team from '@/modules/callcenter/components/Sidebar.Team';
import {BIconBroadcast,BIconPersonLinesFill,BIconTelephone,BIconCalendarWeek} from 'bootstrap-vue';
export default {
    props: ['user','sidebar'],
    components: {
      Team,
      BIconBroadcast,BIconPersonLinesFill,BIconTelephone,BIconCalendarWeek
    },
    methods: {
      setActiveSidebar(name){
          if(!this.sidebar){
              return '';
          }
          return this.sidebar.active === name ? 'active' : '';
      }
    },

    computed: {
      incomingCalls(){
        return this.$store.getters['Callcenter/getCalling'].length;
      }
    }
}
</script>
