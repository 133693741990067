import account from './account';
import manager from './manager';

var routes = [
    {
        path: '/login',
        name: 'app.user.login',
        component: () => import('../../views/Login.vue')
    }
];

routes = routes
    .concat(account)
    .concat(manager);

export default routes;
