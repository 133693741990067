export default {
    namespaced: true,
    state: {
      notifications: []
    },
    mutations: {
      setNotification(state,data){
        state.notifications = Object.assign([],data);
      }
    },
    getters: {
      getRT(state){
        return state.notifications;
      },
      getEvent(state){
        return function(name){
          return state.notifications.find(o => o.event === name);
        };
      }
    },
    actions: {
      addNotification({commit, getters}, data){
        let events = getters.getRT;
        events.push(data);
        return commit('setNotification', events);
      },

      removeEvent({commit, getters}, event){
        let events = getters.getRT.filter(o => o.event !== event);
        return commit('setNotification', events);
      }
    }
};
