export default class User{

    constructor(data){
        this.data = data;
    }

    get(name){
        if(name === undefined){
            return this.data;
        }

        return this.data[name] || null;
    }

    fullName(){
        let arr = [];

        if(this.get('firstName')){
            arr.push(this.get('firstName'));
        }

        if(this.get('lastName')){
            arr.push(this.get('lastName'));
        }

        if(arr.length > 0){
            return arr.join(' ');
        }

        return this.get('email');
    }

    getInitials(){
      let initials = "";
      if(this.get('firstName')){
          initials += this.get('firstName')[0];
      }

      if(this.get('lastName')){
          initials += this.get('lastName')[0];
      }

      return initials;
    }
    
    hasPermission(permissions){
      if(Array.isArray(permissions)){
        let checker = (arr, target) => target.every(v => arr.includes(v));
        return checker(this.get('permissions'), permissions);
      }

      return this.get('permissions').includes(permissions);
    }

    getPackageFeature(placeholder){
      return this.get('package').features.find(o => o.placeholder === placeholder);
    }

}
