<template>
    <div id="app">
        <Sidebar :sidebar="sidebar" :user="user"/>
        <div class="page-container">
            <Navbar :navbar="navbar" :user="user"/>
            <div class="page-content">
                <router-view :user="user" @set-sidebar="setSidebar" @set-navbar="setNavbar" />
            </div>
        </div>
        <b-modal ref="modalUserIteraction" id="modal-user-interaction" @ok="ok()" no-close-on-backdrop>
          <template #modal-header>
            <h5>Enable incoming/outgoing sounds</h5>
          </template>
            Because you did not had any interaction with our app, the incoming call sounds won't take place.<br>
            Just close this modal and continue your work!<br><br>
            Thanks!<br>
            Callstr team
            <template #modal-footer="{ok}">
              <b-button variant="success" @click="ok()">Ok</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Sidebar from '@/common/components/Sidebar';
import Navbar from '@/common/components/Navbar';
export default {
    props: ['user'],
    components: {
      Sidebar,
      Navbar
    },

    data(){
      return {
        sidebar: null,
        navbar: null,
        channels: {
          records: [],
          total: 0,
          pagination: {}
        },
        titleNotifInterval: {
          title: document.title,
          fn: null
        }
      };
    },

    methods: {
      setSidebar(data){
        this.sidebar = data;
      },

      setNavbar(data){
          this.navbar = data;
      },

      ok(){
        console.log("ok")
      },

      init(){
        if(this.$store.getters['Callcenter/isInitialized']){
          return;
        }

        this.$callstr.signal.on("connect", () => {
          console.log('CONNECTED');
          this.$callstr.signal.registerChannels();
        });

        this.$callstr.signal.on("disconnect", () => {
          console.log('DISCONNECTED');
        });

        this.$callstr.signal.on("new-version", (data) => {
          console.log('NEW VERSION', data);
        });

        this.$api.get('channels', {params: {perPage: 12}}).then(res => {
            this.channels = res.data;
            this.channels.records = this.channels.records.map(item => {
              item.clients = [];
              item.users = [];
              return item;
            });

            this.$store.dispatch('Callcenter/addChannels', this.channels.records);

            //let rooms = this.channels.records.map(item => item.id);
            this.$callstr.signal.registerChannels();
        });

        this.$callstr.signal.on('visitors', (data) => {
          let {online, room, inCall, calling} = data;

          let channels = this.$store.getters['Callcenter/getChannels'];
          let channelIndex = channels.findIndex(o => o.id === room);
          if(channelIndex !== -1){
            channels[channelIndex].clients = {
              online: online
            };
          }

          this.$store.dispatch('Callcenter/addChannels', channels);

          let roomCalling = calling.filter(v => v.room === room);
          let storeCalling = this.$store.getters['Callcenter/getCalling']
            .filter(v => v.room !== room)
            .concat(roomCalling);

          let roomInCall = inCall.filter(v => v.room === room);
          let storeInCall = this.$store.getters['Callcenter/getInCall']
            .filter(v => v.room !== room)
            .concat(roomInCall);

          if(this.titleNotifInterval.fn !== null){
            clearInterval(this.titleNotifInterval.fn);
            this.titleNotifInterval.fn = null;
            document.title = this.titleNotifInterval.title;
          }

          if(storeCalling.length > 0){
            if(this.$store.getters['Callcenter/getActiveCall'] === null && this.$store.getters['Callcenter/getUserCallAvailability'] === true){
              this.titleNotifInterval.fn = setInterval(() => {
                document.title = (document.title === this.titleNotifInterval.title ? `${storeCalling.length} incoming calls` : this.titleNotifInterval.title)
              },1000);
              this.$store.dispatch('Sound/playIncomingSound');
            }
          }
          else{
            this.$store.dispatch('Sound/stopIncomingSound');
          }

          this.$store.dispatch('Callcenter/addCalling', storeCalling);
          this.$store.dispatch('Callcenter/addInCall', storeInCall);
        });

        this.$callstr.signal.on('users-online-platform', (data) => {
          this.$store.dispatch('Callcenter/addUsersOnlineInPlatform', data);
        });

        /*
        this.$callstr.signal.on('user-status', (data) => {
          this.$store.dispatch('Notification/addNotification', {
            event: (this.user.get('id') === data.userId ? "onMyStatusUpdate" : "onTeamStatusUpdate"),
            online: data
          });
        });
        */

        this.$callstr.signal.on('waiting-room', ({data}) => {
          let waitingRoom = this.$store.getters['Callcenter/getWaitingRoom'];

          if(waitingRoom.length !== data.length){
            this.$store.dispatch('Sound/playWaitingRoomSound');
          }

          this.$store.dispatch('Callcenter/addWaitingRoom', data);
        });

        this.$store.dispatch('Callcenter/addInitialization', true);
      }


    },

    mounted(){
      this.$store.dispatch('Sound/tryPlaySound').then((value) => {
        if(!value){
          this.$refs['modalUserIteraction'].show();
        }
      });

      this.init();
    }
}
</script>
