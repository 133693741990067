import config from './../config';

var routes = [
    {
        path: '/',
        name: 'app.dashboard',
        component: () => import('../views/Dashboard.vue'),
    },
];

for(let module in config.modules){
    routes = routes.concat(config.modules[module].routes);
}

routes = routes.concat([
  {
      path: '/access-forbidden',
      name: 'app.errors.403',
      component: () => import('../views/Errors/Error403.vue')
  },
  {
      path: '*',
      name: 'app.errors.404',
      component: () => import('../views/Errors/Error404.vue')
  }
]);

export default routes;
